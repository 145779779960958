import React from "react";
import img1 from "../../../img/gallery/fine/lookinggood/1.jpg";
import img2 from "../../../img/gallery/fine/lookinggood/2.jpg";
import img3 from "../../../img/gallery/fine/lookinggood/3.jpg";
import img4 from "../../../img/gallery/fine/lookinggood/4.jpg";
import img5 from "../../../img/gallery/fine/lookinggood/5.jpg";
import img6 from "../../../img/gallery/fine/lookinggood/6.jpg";
import img7 from "../../../img/gallery/fine/lookinggood/7.jpg";
import img8 from "../../../img/gallery/fine/lookinggood/8.jpg";
import img9 from "../../../img/gallery/fine/lookinggood/9.jpg";
import img10 from "../../../img/gallery/fine/lookinggood/10.jpg";
import img11 from "../../../img/gallery/fine/lookinggood/11.jpg";
import img12 from "../../../img/gallery/fine/lookinggood/12.jpg";
import { SRLWrapper } from "simple-react-lightbox";

class LookingGood extends React.Component {
  options = {
    buttons: {
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showThumbnailsButton: true,
      size: "40px",
    },
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <h1 className="my-3">Looking Good</h1>
        <div className="row text-center">
          <SRLWrapper options={this.options}>
            <a href={img1}>
              <img
                src={img1}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img2}>
              <img
                src={img2}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img3}>
              <img
                src={img3}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img4}>
              <img
                src={img4}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img5}>
              <img
                src={img5}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img6}>
              <img
                src={img6}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img7}>
              <img
                src={img7}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img8}>
              <img
                src={img8}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img9}>
              <img
                src={img9}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img10}>
              <img
                src={img10}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img11}>
              <img
                src={img11}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
            <a href={img12}>
              <img
                src={img12}
                alt=""
                className="col-lg-3 col-md-4 col-xs-6 p-1"
                height="200px"
              />
            </a>
          </SRLWrapper>
        </div>
      </div>
    );
  }
}

export default LookingGood;
