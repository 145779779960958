import React from "react";
import imgLookingGood from "../../img/gallery/fine/lookinggood.jpg";
import imgHavingFun from "../../img/gallery/fine/havingfun.jpg";
import imgShowTime from "../../img/gallery/fine/showtime.jpg";
import { connect } from "react-redux";

class Fine extends React.Component {
  state = {
    page: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setState({ page: "" });
  }

  render() {
    return (
      <div className="mb-5">
        <h1 className="my-3 d-inline-block">A Fine Tuned Jewelry</h1>
        <h5 className="my-3 d-inline-block">Shenkar Final Project</h5>
        <div className="row container my-3">
          <div
            className="card border border-0 bg-transparent containerImg col-lg-4"
            onClick={() => this.props.updatePortfolioPage("lookingGood")}
          >
            {" "}
            <div className="card-img-top imgWrapper">
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgLookingGood}
              />
              <div className="center">Looking Good</div>{" "}
            </div>
            <div>
              <h5 className="my-1">Looking Good</h5>
              <p>Sometimes you have the desire to go out and just look good.</p>
              <p>
                This collection was designed for women among us who like to look
                good, without spending too much time.
              </p>
            </div>
          </div>

          <div
            className="card border border-0 bg-transparent containerImg col-lg-4"
            onClick={() => this.props.updatePortfolioPage("havingFun")}
          >
            <div className="card-img-top imgWrapper">
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgHavingFun}
              />
              <div className="center">Having Fun</div>
            </div>
            <div>
              <h5 className="my-1">Having Fun</h5>
              <p>
                Sometimes you just want to walk around, enjoy the surroundings
                and fit into them, so no one disturbs you or recognizes you.
              </p>
              <p>
                This collection was designed as a reminder of the fun in life.
              </p>
            </div>
          </div>
          <div
            className="card border border-0 bg-transparent containerImg col-lg-4"
            onClick={() => this.props.updatePortfolioPage("showTime")}
          >
            {" "}
            <div className="card-img-top imgWrapper">
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgShowTime}
              />
              <div className="center">Show Time</div>{" "}
            </div>
            <div>
              <h5 className="my-1">Show Time</h5>
              <p>
                Sometimes you have the desire to go out and be the one and only
                – to be unique.
              </p>
              <p>
                This collection has been designed to let you know that it's time
                to feel special and be sure about it.
              </p>
            </div>
          </div>
        </div>
        <div className="text-center my-5 pointer font-weight-bolder">
          <h2 onClick={() => this.props.updatePortfolioPage("presentation")}>
            Project Presentation Gallery
          </h2>
        </div>
        <div className="row my-5">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/69998107"
            width="640"
            height="360"
            frameborder="0"
            allowfullscreen
            className="col-12"
          ></iframe>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listings,
    portfolioPage: state.portfolioPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListings(state) {
      dispatch({
        type: "updateListings",
        payload: state,
      });
    },
    updatePortfolioPage(state) {
      dispatch({
        type: "updatePortfolioPage",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Fine);
