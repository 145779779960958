/* eslint-disable default-case */
const initialState = {
  listings: [],
  portfolioPage: "",
};

function rootReducer(state = initialState, action) {
  // console.log("Root: ", action);
  switch (action.type) {
    case "updateListings":
      state = { ...state, listings: action.payload };
      break;
    case "updatePortfolioPage":
      state = { ...state, portfolioPage: action.payload };
      break;
  }
  return state;
}

export default rootReducer;
