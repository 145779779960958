/* eslint-disable no-useless-escape */
import React, { Component } from "react";

class Contact extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    address: "",
    agree: false,
    message: "",
    alert: false,
    invalid: null,
    invalidEmail: null,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  validateEmail = (email) => {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
      email
    );
  };

  async onSubmit() {
    if (
      this.state.name !== "" &&
      this.state.email !== "" &&
      this.state.message !== ""
    ) {
      if (this.validateEmail(this.state.email)) {
        await window.Email.send({
          SecureToken: "ba57ba93-4735-4b7b-a66f-b027ba959dcb",
          To: "info@dl-jewelry.com",
          From: "generice00@gmail.com",
          Subject: `Email from DL-Jewelry.com - ${this.state.name} ${this.state.email}`,
          Body: `___Name: ${this.state.name}\n\n___Email: ${this.state.email}\n\n___Phone: ${this.state.phone}\n\n___Address: ${this.state.address}\n\n___Message: ${this.state.message}\n\n___Agree to receive email: ${this.state.agree}`,
        }).then((message) => {
          console.log(message);
          if (message === "OK") {
            this.setState({
              alert: true,
            });
            setTimeout(() => this.setState({ alert: false }), 10000);
            setTimeout(() => this.clearForm(), 1000);
          }
        });
      } else {
        this.setState({
          invalidEmail: true,
        });
        setTimeout(() => this.setState({ invalidEmail: false }), 5000);
      }
    } else {
      this.setState({
        invalid: true,
      });
      setTimeout(() => this.setState({ invalid: false }), 5000);
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  clearForm = () => {
    this.setState({ name: "" });
    this.setState({ email: "" });
    this.setState({ phone: "" });
    this.setState({ address: "" });
    this.setState({ message: "" });
    document.getElementById("gridCheck").checked = false;
  };

  render() {
    return (
      <div className="container">
        <h3 className="my-3">Let's keep in touch</h3>
        <p>
          Manoff, Misgav
          <br />
          Israel
          <br />
          Email: <a href="mailto:info@dl-jewelry.com">Info@DL-Jewelry.com</a>
          <br />
          Tel: <a href="tel:+972522847058">+972-52-2847058</a>
          <br />
        </p>
        <div className="row">
          <div className="col-lg-6 row">
            <div className="col-lg-6">
              <label htmlFor="inputName" className="form-label">
                Name (required)
              </label>
              <input
                required
                type="text"
                name="name"
                className="form-control mb-3"
                id="inputName"
                value={this.state.name}
                onChange={(e) => this.onChange(e)}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="inputEmail4" className="form-label">
                Email (required)
              </label>
              <input
                required
                type="email"
                name="email"
                className="form-control mb-3"
                id="inputEmail4"
                value={this.state.email}
                onChange={(e) => this.onChange(e)}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="inputPhone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                className="form-control mb-3"
                id="inputPhone"
                value={this.state.phone}
                onChange={(e) => this.onChange(e)}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="inputAddress" className="form-label">
                Address
              </label>
              <input
                type="text"
                name="address"
                className="form-control mb-3"
                id="inputAddress"
                value={this.state.address}
                onChange={(e) => this.onChange(e)}
              />
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label"
              >
                Message (required)
              </label>
              <textarea
                required
                name="message"
                className="form-control mb-3"
                id="exampleFormControlTextarea1"
                rows="3"
                value={this.state.message}
                onChange={(e) => this.onChange(e)}
              ></textarea>
            </div>
            <div className="col-lg-12">
              <div className="form-check">
                <input
                  className="form-check-input mb-3"
                  type="checkbox"
                  id="gridCheck"
                  name="agree"
                  onChange={(e) => this.setState({ agree: !this.state.agree })}
                />
                <label className="form-check-label" htmlFor="gridCheck">
                  I agree to receive updates and promotions from time to time.
                </label>
              </div>
            </div>
            <div className="col-lg-6 p-2">
              <button
                type="button"
                className="btn btn-dark col-12 mb-3"
                onClick={() => this.onSubmit()}
              >
                Submit form
              </button>
            </div>

            <div className="col-lg-6 p-2">
              <button
                type="button"
                className="btn btn-warning col-12 mb-3"
                onClick={() => this.clearForm()}
              >
                Clear form
              </button>
            </div>
            {this.state.alert ? (
              <div className="col-12 alert alert-success mb-3" role="alert">
                Message has been sent successfully. Thank you!
              </div>
            ) : null}
            {this.state.invalidEmail ? (
              <div className="col-12 alert alert-danger mb-3" role="alert">
                Please fill a correct email address.
              </div>
            ) : null}
            {this.state.invalid ? (
              <div className="col-12 alert alert-danger mb-3" role="alert">
                Please fill in all the required fields.
              </div>
            ) : null}
          </div>
          <div className="col-lg-6">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d214563.69955630903!2d35.23434546699157!3d32.830479584440035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sil!4v1616078302431!5m2!1sen!2sil"
              width="100%"
              height="100%"
              title="locationMap"
              className="border border-2 rounded mb-3"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
