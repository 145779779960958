import { Component } from "react";
import { connect } from "react-redux";

class Axios extends Component {
  api_key = "fa62atmrcvmbbc8l2wtffss7";
  shop_name = "DLJD";
  // URL = "https://thingproxy.freeboard.io/fetch/";
  URL = "";
  async componentDidMount() {
    // eslint-disable-next-line no-undef
    await $.ajax({
      url:
        this.URL +
        "https://openapi.etsy.com/v2/shops/" +
        this.shop_name +
        "/listings/active.js?api_key=" +
        this.api_key +
        "&includes=MainImage&fields=url,price,title,shop_section_id,description",
      dataType: "jsonp",
      success: (data) => {
        // console.log("Request received: " + JSON.stringify(data.results));
        this.props.updateListings(data.results);
      },
      error: (data) => {
        console.log("error: " + JSON.stringify(data));
      },
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListings(state) {
      dispatch({
        type: "updateListings",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Axios);
