import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./redux/reducers";
import App from "./App";
import ApiAxios from "./api/axios";
import reportWebVitals from "./reportWebVitals";
// import {video} from "./video/bg.mp4"
import Video from "../src/video/bg.mp4";
import SimpleReactLightbox from "simple-react-lightbox";


const store = createStore(reducers);

ReactDOM.render(
  <React.StrictMode>
    <SimpleReactLightbox>
      <Provider store={store}>
        <BrowserRouter>
          <video autoPlay loop muted src={Video}></video>
          <ApiAxios />
          <App />
        </BrowserRouter>
      </Provider>
    </SimpleReactLightbox>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
