import "./App.css";
import { Route, BrowserRouter } from "react-router-dom";
import Navbar from "./components/Navigation";
import React, { Component } from "react";
import welcome from "./components/welcome";
import portfolio from "./components/portfolio";
import about from "./components/about";
import contact from "./components/contact";
import store from "./components/store";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <div className="container my-5" id="main">
            <Route exact path="/" component={welcome} />
            <Route path="/portfolio" component={portfolio} />
            <Route exact path="/about" component={about} />
            <Route exact path="/contact" component={contact} />
            <Route exact path="/store" component={store} />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
