import React, { Component } from "react";
import { connect } from "react-redux";

class Store extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (window.etsyData) {
      this.props.updateListings(window.etsyData);
    }
  }

  render() {
    let listings = this.props.listings.map((listing, i) => {
      return (
        <a
          href={listing.url}
          target="blank"
          className="card border border-0 col-xl-2 col-lg-3 col-md-4 col-sm-6 bg-transparent"
          key={i}
        >
          <img
            src={listing.MainImage.url_170x135}
            className="card-img-top border border-2 rounded"
            alt={listing.description}
            width="100"
          />
          <div className="card-body">
            <h5 className="card-title">{listing.title.slice(0, 30)}...</h5>
            <p className="card-text">{listing.description.slice(0, 100)}...</p>
            <strong>
              <p className="card-text">{listing.price} ILS</p>
            </strong>
          </div>
        </a>
      );
    });
    return (
      <div>
        <div className="row my-5 text-center">
          <div className="col-12 mb-3">
            <h1 className="p-0 m-0">
              My Store Items on{" "}
              <a href="https://www.etsy.com/il-en/shop/DLJD" target="blank">
                Etsy
              </a>
              :
            </h1>
            <br />
          </div>
          {listings}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListings(state) {
      dispatch({
        type: "updateListings",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Store);
