import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../img/DL_Jewelry_Logo.png";
import Navbar from "react-bootstrap/Navbar";
import { Nav } from "react-bootstrap";

class Navigation extends Component {
  onClickPort = () => {
    this.props.updatePortfolioPage("");
  };

  render() {
    return (
      <div className="container-fluid p-0 m-0">
        <div className="text-center">
          <Link to="/">
            <div id="nav-head">
              <img
                className="d-none d-lg-inline-block d-xl-inline-block text-dark"
                src={Logo}
                alt="logo"
                width="200"
              />
              <img
                className="d-inline-block d-lg-none d-xl-none text-dark"
                src={Logo}
                alt="logo"
                width="100"
              />
              <br />
              <h5 className="d-none d-lg-block d-xl-block text-dark">
                Dana Levy Jewelry Design
              </h5>
            </div>
          </Link>
        </div>
        <Navbar
          className="opac px-5"
          collapseOnSelect
          expand="lg"
          bg="light"
          variant="light"
          id="nav-menu"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/" className="mx-2">
                HOME
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/portfolio"
                className="mx-2"
                onClick={() => {
                  this.onClickPort();
                }}
              >
                PORTFOLIO
              </Nav.Link>
              <Nav.Link as={Link} to="/about" className="mx-2">
                ABOUT ME
              </Nav.Link>
              <Nav.Link as={Link} to="/contact" className="mx-2">
                CONTACT ME
              </Nav.Link>
              <Nav.Link as={Link} to="/store" className="mx-2">
                ONLINE STORE
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav className="mx-auto">
            <Nav.Link className="mx-2" href="tel:+972522847058">
              Call me
            </Nav.Link>
            <Nav.Link className="mx-2" href="mailto:info@dl-jewelry.com">
              Email me
            </Nav.Link>
          </Nav>
        </Navbar>
        <nav className="navbar fixed-bottom navbar-light bg-light opac">
          <div className="text-center mx-auto">
            © 2021 by Dana Levy. All rights reserved. Powered by:
            <a href="https://www.wibime.dev" target="blank">
              Wibime.Dev
            </a>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listings,
    portfolioPage: state.portfolioPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListings(state) {
      dispatch({
        type: "updateListings",
        payload: state,
      });
    },
    updatePortfolioPage(state) {
      dispatch({
        type: "updatePortfolioPage",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
