/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { connect } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import img1 from "../img/welcome/mini-1.jpg";
import img2 from "../img/welcome/mini-2.jpg";
import img3 from "../img/welcome/mini-3.jpg";
import img4 from "../img/welcome/mini-4.jpg";
import img6 from "../img/welcome/mini-6.jpg";
import img7 from "../img/welcome/mini-7.jpg";
import img8 from "../img/welcome/mini-8.jpg";
import img9 from "../img/welcome/mini-9.jpg";
import img10 from "../img/welcome/mini-10.jpg";
import img11 from "../img/welcome/mini-11.jpg";
import img12 from "../img/welcome/mini-12.jpg";
import img13 from "../img/welcome/mini-13.jpg";
import img14 from "../img/welcome/mini-14.jpg";

class Welcome extends Component {
  state = {
    name: "",
    phone: "",
    email: "",
    agree: false,
    alert: false,
    invalid: null,
    invalidEmail: null,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  validateEmail = (email) => {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
      email
    );
  };

  async onSubmit() {
    if (this.state.name !== "" && this.state.email !== "") {
      if (this.validateEmail(this.state.email)) {
        await window.Email.send({
          SecureToken: "ba57ba93-4735-4b7b-a66f-b027ba959dcb",
          To: "info@dl-jewelry.com",
          From: "generice00@gmail.com",
          Subject: `I want to join the club - DL-Jewelry.com - ${this.state.name} ${this.state.email}`,
          Body: `___Name: ${this.state.name}\n\n___Email: ${this.state.email}\n\n___Phone: ${this.state.phone}\n\n___Agree to receive email: ${this.state.agree}`,
        }).then((message) => {
          console.log(message);
          if (message === "OK") {
            this.setState({
              alert: true,
            });
            setTimeout(() => this.setState({ alert: false }), 10000);
            setTimeout(() => this.clearForm(), 1000);
          }
        });
      } else {
        this.setState({
          invalidEmail: true,
        });
        setTimeout(() => this.setState({ invalidEmail: false }), 5000);
      }
    } else {
      this.setState({
        invalid: true,
      });
      setTimeout(() => this.setState({ invalid: false }), 5000);
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  clearForm = () => {
    this.setState({ name: "" });
    this.setState({ email: "" });
    this.setState({ phone: "" });
    document.getElementById("agreeCheck").checked = false;
  };

  render() {
    return (
      <div>
        <div className="row my-5">
          <div className="col-12 mb-3 text-center p-2">
            <h1 className="p-0 m-0">JEWELRY</h1>
            <h5 className="p-0 m-0">&</h5>
            <h1 className="p-0 m-0">ACCESSORIES</h1>
            <h5 className="p-0 m-0">&</h5>
            <h1 className="p-0 m-0">ELEMENTS</h1>
            <br />
            <h3>
              <i>By</i>
            </h3>
            <h3>
              <i>Dana Levy</i>
            </h3>
          </div>
          <form className="col-lg-6 mb-3">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Full name (required)"
                    required
                    value={this.state.name}
                    onChange={(e) => this.onChange(e)}
                    name="name"
                  />
                  <label htmlFor="name">Full name (required)</label>
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email address (required)"
                    required
                    value={this.state.email}
                    onChange={(e) => this.onChange(e)}
                    name="email"
                  />
                  <label htmlFor="email">Email address (required)</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    placeholder="Phone number"
                    value={this.state.phone}
                    onChange={(e) => this.onChange(e)}
                    name="phone"
                  />
                  <label htmlFor="phone">Phone number</label>
                </div>
              </div>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="agreeCheck"
              />
              <label className="form-check-label" htmlFor="agreeCheck">
                I agree to receive updates and promotions from time to time{" "}
              </label>
            </div>
            <div className="d-grid gap-2">
              <button
                className="btn btn-dark mb-3"
                type="button"
                onClick={() => this.onSubmit()}
              >
                Join my club
              </button>
              {this.state.alert ? (
                <div className="col-12 alert alert-success mb-3" role="alert">
                  Message has been sent successfully. Thank you!
                </div>
              ) : null}
              {this.state.invalidEmail ? (
                <div className="col-12 alert alert-danger mb-3" role="alert">
                  Please fill a correct email address.
                </div>
              ) : null}
              {this.state.invalid ? (
                <div className="col-12 alert alert-danger mb-3" role="alert">
                  Please fill in all the required fields.
                </div>
              ) : null}
            </div>
          </form>
          <div className="col-lg-6 text-center">
            <Carousel fade controls={false}>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img1} alt="1st slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img2} alt="2nd slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img3} alt="3rd slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img4} alt="4th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img6} alt="6th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img7} alt="7th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img8} alt="8th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img9} alt="9th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img10} alt="10th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img11} alt="11th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img12} alt="12th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img13} alt="13th slide" />
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <img className="d-inline-block" src={img14} alt="14th slide" />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    games: state.games,
    football: state.football,
    basketball: state.basketball,
    comments: state.comments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updategames(state) {
      dispatch({
        type: "updateGames",
        payload: state,
      });
    },
    updateFootball(state) {
      dispatch({
        type: "updateFootball",
        payload: state,
      });
    },
    updateBasketball(state) {
      dispatch({
        type: "updateBasketball",
        payload: state,
      });
    },
    updateComments(state) {
      dispatch({
        type: "updateComments",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
