import React, { Component } from "react";
import { connect } from "react-redux";
import img1 from "../img/shenkar.PNG";
import thumb from "../img/shenkar-thumb300.png";
import { SRLWrapper } from "simple-react-lightbox";
import me from "../img/dana_levy.jpeg";

class About extends Component {
  options = {
    buttons: {
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showThumbnailsButton: true,
      size: "40px",
    },
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="container row my-2">
        <div className="col-md-6">
          <h5>Dana Levy</h5>
          <p>
            Born on 1984 in Israel and grew up in the Galilee. <br />
            In 2006, after her military service, she moved to Tel Aviv.
            <br />
            Today, after some years in the heart of Israel, she came back to the
            Galilee and living in Manoff.
          </p>
          <p>
            Her childhood dream to develop in the jewelry industry was fulfilled
            in 2009 when she began her B.A. degree studies in the Department of
            Jewelry Design at Shenkar College of Engineering and Design in Ramat
            Gan, from which she graduated with honors in 2013.
          </p>
          <p>
            Her jewelry is made ​​of a combination of materials: metals such as
            brass, silver, gold; polymers; textiles; gems and semi-precious
            stones. Dana also designs fashion jewelry and fashion accessories.
          </p>
          <p>
            It is always important for Dana to create each piece in the highest
            manufacturing quality.
          </p>
        </div>
        <div className="col-md-6 text-center">
          <img
            className="img border border-2 rounded"
            src={me}
            alt="Dana Levy"
            width="50%"
          />
        </div>
        <div className="col-12">
          <br />
        </div>
        <div className="col-md-6 text-center">
          <SRLWrapper options={this.options}>
            <a href={img1}>
              <img src={thumb} alt="" />
            </a>
          </SRLWrapper>
          <a
            href="https://www.shenkar.ac.il/he/news_items/joyacor2014-graduates-finalists"
            target="blank"
          >
            Link to Shenkar Colledge of Engineering and Design article
          </a>
        </div>
        <div className="col-md-6 text-center h5">
          <strong>
            <p>
              In 2013, Dana qualified for the finals of the international design
              competition JOYACOR in Cardoba, Spain, in the category of young
              talents, starring in the top ten, out of hundreds of contestants
              from around the world.
            </p>
          </strong>
        </div>
        <div className="col-12">
          <br />
        </div>
        <hr />
        <div className="text-center">
          <h4>My Guideline</h4>
          <strong className="h5">
            <p>
              The Jewelry making process must maintain an important balance
              between technology and traditional handicrafts.
            </p>
            <p>
              My jewelry design is based on a wide range of options which will
              give the woman wearing them to feel special, unique & desirable.
            </p>
          </strong>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    games: state.games,
    football: state.football,
    basketball: state.basketball,
    comments: state.comments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updategames(state) {
      dispatch({
        type: "updateGames",
        payload: state,
      });
    },
    updateFootball(state) {
      dispatch({
        type: "updateFootball",
        payload: state,
      });
    },
    updateBasketball(state) {
      dispatch({
        type: "updateBasketball",
        payload: state,
      });
    },
    updateComments(state) {
      dispatch({
        type: "updateComments",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
