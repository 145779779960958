import React, { Component } from "react";
import { connect } from "react-redux";
import Textile from "./portfolio/textile";
import Polymers from "./portfolio/polymers";
import Metal from "./portfolio/metal";
import Other from "./portfolio/other";
import Fine from "./portfolio/fine";
import imgTextile from "../img/gallery/textile.jpg";
import imgPolymers from "../img/gallery/polymers.jpg";
import imgMetal from "../img/gallery/metal.jpg";
import imgFine from "../img/gallery/fine.jpg";
import imgOther from "../img/gallery/other.jpg";
import HavingFun from "./portfolio/fine/havingfun";
import LookingGood from "./portfolio/fine/lookinggood";
import ShowTime from "./portfolio/fine/showtime";
import Presentation from "./portfolio/fine/presentation";

class Portfolio extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updatePortfolioPage("");
  }
  render() {
    return (
      <div>
        {this.props.portfolioPage !== "" ? (
          <div className="nav nav-pills nav-fill opac">
            <button
              className="btn btn-light mx-1 nav-item"
              onClick={() => this.props.updatePortfolioPage("textile")}
            >
              Textile
            </button>
            <button
              className="btn btn-light mx-1 nav-item"
              onClick={() => this.props.updatePortfolioPage("metal")}
            >
              Metal
            </button>
            <button
              className="btn btn-light mx-1 nav-item"
              onClick={() => this.props.updatePortfolioPage("fine")}
            >
              Fine Tuned String Jewelry
            </button>
            <button
              className="btn btn-light mx-1 nav-item"
              onClick={() => this.props.updatePortfolioPage("other")}
            >
              Other Forms Of Design
            </button>
            <button
              className="btn btn-light mx-1 nav-item"
              onClick={() => this.props.updatePortfolioPage("polymers")}
            >
              Polymers
            </button>
          </div>
        ) : null}
        {this.props.portfolioPage === "polymers" ? (
          <Polymers />
        ) : this.props.portfolioPage === "metal" ? (
          <Metal />
        ) : this.props.portfolioPage === "textile" ? (
          <Textile />
        ) : this.props.portfolioPage === "fine" ? (
          <Fine />
        ) : this.props.portfolioPage === "showTime" ? (
          <ShowTime />
        ) : this.props.portfolioPage === "havingFun" ? (
          <HavingFun />
        ) : this.props.portfolioPage === "lookingGood" ? (
          <LookingGood />
        ) : this.props.portfolioPage === "presentation" ? (
          <Presentation />
        ) : this.props.portfolioPage === "other" ? (
          <Other />
        ) : (
          <div className="row container my-3">
            <div
              className="containerImg col-lg-2 mb-2"
              onClick={() => this.props.updatePortfolioPage("textile")}
            >
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgTextile}
              />
              <p className="center">Textile</p>
            </div>
            <div
              className="containerImg col-lg-2 mb-2"
              onClick={() => this.props.updatePortfolioPage("metal")}
            >
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgMetal}
              />
              <p className="center">Metal</p>
            </div>
            <div
              className="containerImg col-lg-4 mb-2"
              onClick={() => this.props.updatePortfolioPage("fine")}
            >
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgFine}
              />
              <p className="center">Fine Tuned String Jewelry</p>
            </div>
            <div
              className="containerImg col-lg-2 mb-2"
              onClick={() => this.props.updatePortfolioPage("other")}
            >
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgOther}
              />
              <p className="center">Other Forms Of Design</p>
            </div>
            <div
              className="containerImg col-lg-2 mb-2"
              onClick={() => this.props.updatePortfolioPage("polymers")}
            >
              <img
                className="imgText border border-1 rounded"
                alt=""
                src={imgPolymers}
              />
              <p className="center">Polymers</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listings: state.listings,
    portfolioPage: state.portfolioPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListings(state) {
      dispatch({
        type: "updateListings",
        payload: state,
      });
    },
    updatePortfolioPage(state) {
      dispatch({
        type: "updatePortfolioPage",
        payload: state,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
